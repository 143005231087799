import React from 'react';
import ArticleTemplate from '../ArticleTemplate';

const ForArtists = () => {
    return (
        <ArticleTemplate
            title='Growspective for Artists'
            subtitle='Achieve your goals with our comprehensive consulting services'>
            <p>
                Whether you're a band, a solo artist, a painter, a writer, or any other type of artist, Growspective
                can accompany you at any stage of your journey to success. We offer a wide range of consulting services to help you
                achieve your goals.
            </p>
            <strong>What we offer:</strong>
            <ul>
                <li><u>Strategic Planning and Development</u>: Growspective will accompany you all along throughout your journey.</li>
                <li><u>Online Presence</u>: We will provide you a website and strenghten your presence on social media.</li>
                <li><u>Marketing and Promotion</u>: We will help you promote your content online and physically.</li>
                <li><u>Networking</u>: As we love connecting people together. We work with various partners for merch, gigs and recording, as well as for finding sponsorships and fundings.</li>
            </ul>
            <strong>We are working with:</strong>
            <div className='flex m-auto w-full mt-3 align-items-center justify-content-center'>
                <a href='https://janapelikan.com' target='_blank' rel='noopener noreferrer' className='no-underline'><div style={{fontFamily: 'Montagu Slab'}} className='text-xl mr-5 ml-5'>JANA PELIKAN</div></a>
                <a href='https://panasea-band.com' target='_blank' rel='noopener noreferrer' className='no-underline'><div style={{fontFamily: 'Coptic Revival'}} className='text-xl mr-5 ml-5'>Panasea</div></a>
            </div>
            <p>Why not you? Feel free to reach us to discuss about opportunities!</p>
        </ArticleTemplate>
    );
};

export default ForArtists;