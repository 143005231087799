import './App.css';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import TypewriterText from './TypewriterText';
import ContactForm from './ContactForm';
import Footer from './Footer';
import ContactBlock from './ContactBlock';
import Header from './Header';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Home from './Home';
import Consulting from './articles/Consulting';
import WebDevelopment from './articles/WebDevelopment';
import Technologies from './articles/Technologies';
import Marketing from './articles/Marketing';
import LegalNotice from './articles/Legal';
import ForStartups from './articles/ForStartups';
import ForArtists from './articles/ForArtists';

function App() {
  const [headerLinks, setHeaderLinks] = useState(null);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const handleScroll = (targetId) => {
    const element = document.getElementById(targetId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  useEffect(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [pathname]);


  return (
    <div className="App">
      <Header>
        <div className='header-nav'>
          <Button label='Home' className='p-button-text text-200' onClick={() => navigate('/')} />
          {headerLinks && headerLinks}
          <Button label='Contact' className='p-button-text text-200' onClick={()=>handleScroll('contact-anchor')} />
        </div>
      </Header>

      <Routes>
        <Route path="/" element={<Home setHeaderLinks={setHeaderLinks} handleScroll={handleScroll} />} />
        <Route path='consulting' element={<Consulting />} />
        <Route path='webdev' element={<WebDevelopment />} />
        <Route path='tech' element={<Technologies />} />
        <Route path='marketing' element={<Marketing />} />
        <Route path='legal' element={<LegalNotice />} />
        <Route path='for-startups' element={<ForStartups />} />
        <Route path='for-artists' element={<ForArtists />} />
      </Routes>

      <ContactBlock id='contact-anchor' />

      <Footer />
    </div>
  );
}

export default App;
