import React, { useEffect, useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { CSSTransition } from 'react-transition-group';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { useNavigate } from 'react-router-dom';

const ContactForm = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    });
    const [submitted, setSubmitted] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSend = () => {
        setSubmitted(true);
    };

    useEffect(() => {
        setSubmitted(false);
    }, [navigate]);

    return (
        <div className="contact-form w-full">
            {!submitted &&
            <form /*action='https://formspree.io/f/mbljgggz'*/ method='POST' name='contact'
                data-netlify='true' action='/' >
                <input type="hidden" name="form-name" value="contact" />
                <div className="form-content">
                    <div className="p-field pb-3">
                        <label htmlFor="name">What's your name?*</label>
                        <InputText className='w-full mt-1' id="name" name="name" value={formData.name} onChange={handleInputChange} required />
                    </div>
                    <div className="p-field pb-3">
                        <label htmlFor="email">What's your email address?*</label>
                        <InputText className='w-full mt-1' id="email" name="email" value={formData.email} onChange={handleInputChange} required />
                    </div>
                    <div className="p-field pb-3">
                        <label htmlFor="phone">What's your phone number?</label>
                        <InputText className='w-full mt-1' id="phone" name="phone" value={formData.phone} onChange={handleInputChange} />
                    </div>
                    <div className="p-field pb-3">
                        <label htmlFor="message">What's your message?*</label>
                        <InputTextarea className='w-full mt-1' id="message" name="message" value={formData.message} onChange={handleInputChange} rows={3} autoResize required />
                    </div>
                    <div className="p-field pb-3">
                        <small>*Required fields</small>
                        <br />
                        <small>By submiting this form, you agree with our privacy policy.</small>
                    </div>
                    <Button type='submit' label="Submit" /*onClick={handleSend}*/ />
                </div>
            </form>}
            {submitted &&
            <div className="confirmation-message">
                <p>Thank you for your message!</p>
                <p>We will get back to you shortly.</p>
            </div>
            }
        </div>
    );
};

/*const ContactForm = () => {
  const vismeContainerRef = useRef(null);

  useEffect(() => {
    // Ajouter le script Visme une fois le composant monté
    const script = document.createElement('script');
    script.src = "https://server.fillout.com/embed/v1/";
    script.async = true;
    vismeContainerRef.current.appendChild(script);

    return () => {
      // Supprimer le script lorsque le composant est démonté
      if (vismeContainerRef.current) {
        vismeContainerRef.current.removeChild(script);
      }
    };
  }, []);

  return (
    <div ref={vismeContainerRef} style={{height: '500px'}} className='w-full' data-fillout-id="2B4vM9vpkVus" data-fillout-embed-type="standard" data-fillout-inherit-parameters data-fillout-dynamic-resize></div>
  );
};*/

export default ContactForm;
