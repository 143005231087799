import React from 'react';
import ArticleTemplate from '../ArticleTemplate';

const WebDevelopment = () => {
    return (
        <ArticleTemplate
            title='Online Presence through Web and Mobile Development'
            subtitle='Unlock your digital potential with our expert web and mobile development services'>
            <p>
                In today's digital-first world, having a strong online presence is essential for business growth and customer engagement.
                Our web and mobile development services are designed to help you establish, enhance, and expand your digital footprint.
                With a dedicated team of developers, designers, and strategists, we're committed to creating custom solutions
                that not only meet your needs but also exceed your expectations.
            </p>
            <strong>A full suite of development services tailored to your vision</strong>
            <p>
                Whether you're looking to launch a new website, develop a responsive mobile app, or enhance your existing platform,
                we offer a comprehensive range of development services. From concept to launch, our team supports you every step of the way,
                ensuring your digital platforms are modern, efficient, and fully aligned with your business objectives.
            </p>
            <strong>Custom Website Development</strong>
            <p>
                A custom website provides the flexibility and unique branding needed to stand out in a crowded digital landscape.
                Our developers work with you to create a site tailored to your brand, industry, and audience. We leverage
                cutting-edge technologies to build sites that are visually appealing, fast, secure, and optimized for search engines.
            </p>
            <strong>Mobile App Development for iOS and Android</strong>
            <p>
                Mobile apps offer unique opportunities for customer engagement and brand loyalty. Our team specializes in native and
                cross-platform mobile app development, creating powerful applications for both iOS and Android. From utility apps to
                full-fledged enterprise solutions, we design and develop apps that align with your business needs and provide an
                intuitive user experience.
            </p>
            <strong>E-commerce Development</strong>
            <p>
                For businesses focused on online sales, a robust e-commerce platform is essential. We design and develop e-commerce
                websites and applications that are secure, scalable, and user-friendly. With features like product catalogs, payment
                gateways, and shopping carts, we ensure that your customers enjoy a seamless shopping experience from start to finish.
            </p>
            <strong>Web Application Development</strong>
            <p>
                From internal tools to customer-facing applications, web applications allow for complex functionality and enhanced
                interactivity. Our web application development services focus on creating dynamic, scalable, and high-performance
                applications that cater to your specific business requirements, from process automation to customer engagement tools.
            </p>
        </ArticleTemplate>
    );
};

export default WebDevelopment;
