import React from 'react';
import ArticleTemplate from '../ArticleTemplate';

const ForStartups = () => {
    return (
        <ArticleTemplate
            title='Growspective for Startups'
            subtitle='Achieve your goals with our comprehensive consulting services'>
            <p>
                Whether you're at the stage of idea or well advanced in your project, Growspective
                is ready to accompany you through your journey to success. We offer a wide range of consulting services to help you
                achieve your goals.
            </p>
            <strong>What we offer:</strong>
            <ul>
                <li><u>Strategic Planning and Development</u>: Growspective will accompany you all along throughout your journey.</li>
                <li><u>Online Presence</u>: We will provide you a website and strenghten your presence on social media.</li>
                <li><u>Marketing and Promotion</u>: We will help you promote your content online and physically.</li>
                <li><u>Networking</u>: As we love connecting people together. We will help you finding associates, partners, sponsorships and fundings.</li>
            </ul>
            <strong>We are working with:</strong>
            <div className='flex m-auto w-full mt-3 align-items-center justify-content-center'>
            <a href='http://aisther.com' target='_blank' rel='noopener noreferrer'><img src='logo_aisther.png' alt='aisther.com' style={{width:'auto !important',height: '2rem',filter:'invert(80%)'}} className='mr-5 ml-5' /></a>
            </div>
            <p>Why not you? Feel free to reach us to discuss about opportunities!</p>
        </ArticleTemplate>
    );
};

export default ForStartups;