import { Button } from 'primereact/button';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const ArticleTemplate = ( {children, title, subtitle, date} ) => {
    const navigate = useNavigate();

    return (
        <div className='w-full'>
            <div className='flex m-auto w-full p-3 md:p-8 md:pt-5 md:pb-5 md:w-7'>
                <div className='text-900'>
                    {title && <><span className={`text-2xl font-bold ${!subtitle && 'pb-3'} inline-block`} id='services-anchor'>{title}</span><br /></>}
                    {subtitle && <><span className='text-700 pb-3 inline-block' id='services-anchor'>{subtitle}</span><br /></>}
                    {date && <><span className='text-700 pb-3 inline-block' id='services-anchor'>{date}</span><br /></>}
                    <div className='line-height-3'>
                        {children}
                    </div>
                    
                    <Button label='Home' icon='pi pi-arrow-left' className='p-button-text text-700  ' onClick={() => navigate('/')} />
                </div>
            </div>
        </div>
    );
};

export default ArticleTemplate;